import $ from 'jquery';
import 'what-input';
import './lib/foundation-explicit-pieces';
import 'magnific-popup';

window.jQuery = $;

$(document).ready(function () {
    $(window).scroll(dynHeader);
    dynHeader();
    $(document).foundation();

    // init the toggler script
    setupToggler(document);
});

function dynHeader () {
    if ($(window).scrollTop() > 0) {
        $('header#top-header').addClass('bordered');
        $('.hero-container').first().addClass('bordered');
        $('header#top-header').addClass('compressed');
    } else {
        $('header#top-header').removeClass('bordered');
        $('.hero-container').removeClass('bordered');
        $('header#top-header').removeClass('compressed');
    }
}

function setupToggler (context) {
    $('.leistung-toggler', context).each(function (idx, ele) {
        $(ele).on('click', toggleHandler);
    });
    $('.secondary-leistung-toggler', context).each(function (idx, ele) {
        $(ele).on('click', secondaryToggleHandler);
    });
}

function toggleHandler (e) {
    e.preventDefault();
    e.stopPropagation();

    var $target = e.target.tagName.toLowerCase() === 'a' ? $(e.target).first() : $(e.target).parent().first();

    var $chevron = $target.find('.chevron');
    $chevron.hasClass('down') ? $chevron.removeClass('down').addClass('up') : $chevron.removeClass('up').addClass('down');
    $target.hasClass('down') ? $target.removeClass('down').addClass('up') : $target.removeClass('up').addClass('down');

    var section = document.getElementById($target.data('toggle'));
    var isCollapsed = section.getAttribute('data-collapsed') === 'true';

    if (isCollapsed) {
        expandSection(section);
        section.setAttribute('data-collapsed', 'false');
    } else {
        collapseSection(section);
    }
}

function secondaryToggleHandler (e) {
    e.preventDefault();
    e.stopPropagation();

    var $target = e.target.tagName.toLowerCase() === 'a' ? $(e.target).first() : $(e.target).parent().first();
    $('a.leistung-toggler[data-toggle="' + $target.data('primaryToggler') + '"]').trigger('click');
}

// This is the important part!

function collapseSection (element) {
    // get the height of the element's inner content, regardless of its actual size
    var sectionHeight = element.scrollHeight;

    // temporarily disable all css transitions
    var elementTransition = element.style.transition;
    element.style.transition = '';

    // on the next frame (as soon as the previous style change has taken effect),
    // explicitly set the element's height to its current pixel height, so we
    // aren't transitioning out of 'auto'
    requestAnimationFrame(function () {
        element.style.height = sectionHeight + 'px';
        element.style.transition = elementTransition;

        // on the next frame (as soon as the previous style change has taken effect),
        // have the element transition to height: 0
        requestAnimationFrame(function () {
            element.style.height = 0 + 'px';
        });
    });

    // mark the section as "currently collapsed"
    element.setAttribute('data-collapsed', 'true');
}

function expandSection (element) {
    // get the height of the element's inner content, regardless of its actual size
    var sectionHeight = element.scrollHeight;
    // have the element transition to the height of its inner content
    element.style.height = sectionHeight + 'px';
    // when the next css transition finishes (which should be the one we just triggered)
    element.addEventListener('transitionend', toggleHeight);
    // mark the section as "currently not collapsed"
    element.setAttribute('data-collapsed', 'false');
}

function toggleHeight (e) {
    // remove this event listener so it only gets triggered once
    e.target.removeEventListener('transitionend', toggleHeight);
    // remove "height" from the element's inline styles, so it can return to its initial value
    e.target.style.height = null;
}

function initGallery (element) {
    $(element).find('.referenzen-gallery').each(function () {
        $(this).magnificPopup({
            delegate: 'a',
            type: 'image',
            gallery: {
                enabled: true
            },
            fixedContentPos: false //fixes scrolling problems after closing modal, but allows scrolling while open (minor issue)
        });
    });
}

$('.referenzen-gallery').each(function () {
    $(this).magnificPopup({
        delegate: 'a',
        type: 'image',
        gallery: {
            enabled: true,
            tCounter: '<span class="mfp-counter">%curr% / %total%</span>'
        },
        fixedContentPos: true //fixes scrolling problems after closing modal, but allows scrolling while open (minor issue)
    });
});
